"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _firmUser = require("@/api/firm-user");
var _editForm = require("./editForm");
var _pos = require("@/api/pos");
var _role = require("@/api/role");
var _group = require("@/api/group");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  components: {},
  data: function data() {
    return {
      temp: {},
      model: {},
      formRule: _editForm.formRule,
      formOption: _editForm.formOption,
      btnLoading: false,
      modelChange: false,
      formVisible: false
    };
  },
  watch: {
    'model.form': {
      handler: function handler(val) {
        if (typeof val !== 'undefined') {
          this.modelChange = true;
        }
      },
      immediate: true,
      deep: true
    },
    formVisible: function formVisible() {
      this.handleReset();
    }
  },
  created: function created() {},
  methods: {
    handleClose: function handleClose() {
      var _this = this;
      if (this.btnLoading) {
        return;
      }
      if (this.modelChange) {
        this.$confirm('更改将不会被保存，确定要取消吗？').then(function (_) {
          _this.formVisible = false;
        }).catch(function (_) {});
      } else {
        this.formVisible = false;
      }
    },
    handleReset: function handleReset() {
      this.temp = {};
      this.model.form && this.model.resetFields();
      this.modelChange = false;
    },
    handleCreate: function handleCreate() {
      this.btnLoading = false;
      this.formVisible = true;
    },
    handleUpdate: function handleUpdate(id) {
      var _this2 = this;
      this.formVisible = true;
      (0, _firmUser.getInfo)(id).then(function (response) {
        if (response.code === 0) {
          _this2.temp = response.data;
          _this2.model.setValue(_this2.temp);
        }
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      this.model.submit(function (formData) {
        if (_this3.temp.id) {
          formData.id = _this3.temp.id;
        }
        if (formData.pos_limit && Array.isArray(formData.pos_limit)) {
          formData.pos_limit = formData.pos_limit.join(',');
        }
        if (formData.role_id && Array.isArray(formData.role_id)) {
          formData.role_id = formData.role_id.join(',');
        }
        _this3.btnLoading = true;
        if (formData.id) {
          (0, _firmUser.update)(formData).then(function (response) {
            _this3.btnLoading = false;
            if (!response.code) {
              if (!_this3.temp.id) {
                _this3.temp.id = response.data.id;
              }
              _this3.$emit('updateRow', _this3.temp);
              _this3.formVisible = false;
              _this3.$message.success(response.msg);
            } else {
              _this3.$message.error(response.msg);
            }
          });
        } else {
          (0, _firmUser.create)(formData).then(function (response) {
            _this3.btnLoading = false;
            if (!response.code) {
              if (!_this3.temp.id) {
                _this3.temp.id = response.data.id;
              }
              _this3.$emit('updateRow', _this3.temp);
              _this3.formVisible = false;
              _this3.$message.success(response.msg);
            } else {
              _this3.$message.error(response.msg);
            }
          });
        }
      });
    },
    formMounted: function formMounted() {
      var _this4 = this;
      (0, _pos.getOwner)().then(function (res) {
        res.data.map(function (item) {
          item.label = item.title;
          item.value = String(item.id);
        });
        _this4.model.mergeRule('pos_limit', {
          options: res.data
        });
        _this4.model.setValue('pos_limit', []);
        _this4.$nextTick(function () {
          _this4.model.setValue('pos_limit', _this4.temp.pos_limit);
        });
      });
      (0, _role.getList)().then(function (res) {
        res.data.map(function (item) {
          item.label = item.title;
          item.value = String(item.id);
        });
        _this4.model.mergeRule('role_id', {
          options: res.data
        });
        _this4.model.setValue('role_id', []);
        _this4.$nextTick(function () {
          _this4.model.setValue('role_id', _this4.temp.role_id);
        });
      });
      (0, _group.getList)().then(function (res) {
        res.data.map(function (item) {
          item.label = item.title;
          item.value = item.id;
        });
        _this4.model.mergeRule('group_id', {
          options: res.data
        });
      });
    }
  }
};
exports.default = _default;