"use strict";

var _interopRequireDefault = require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'OperationFilter',
  data: function data() {
    return {
      model: {},
      option: {
        form: {
          inline: true,
          showMessage: true,
          inlineMessage: false,
          statusIcon: false,
          validateOnRuleChange: true,
          size: 'small'
        },
        submitBtn: false,
        resetBtn: false
      },
      rule: [{
        type: 'input',
        field: 'keyword',
        props: {
          placeholder: '动作/行为/用户名'
        }
      }, {
        type: 'select',
        field: 'user_id',
        options: [],
        props: {
          filterable: true,
          placeholder: '用户id'
        }
      }]
    };
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    getAdmin: 'user/getAdminList'
  })), {}, {
    formMounted: function formMounted() {
      var _this = this;
      this.getAdmin().then(function (response) {
        _this.model.mergeRule('user_id', {
          options: response
        });
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      this.model.submit(function (formData) {
        _this2.$emit('submit', formData);
      });
    },
    handleReset: function handleReset() {
      this.model.resetFields();
      this.$emit('reset');
    }
  })
};
exports.default = _default;