"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var importRule = [{
  label: '名称',
  value: 'title',
  required: true
}, {
  label: '备注',
  value: 'remark'
}, {
  label: '排序',
  value: 'sort'
}, {
  label: '创建时间',
  value: 'create_time'
}, {
  label: '更新时间',
  value: 'update_time'
}];
var _default = importRule;
exports.default = _default;