"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
var _default = {
  name: 'PageFilter',
  data: function data() {
    return {
      model: {},
      option: {
        form: {
          inline: true,
          showMessage: true,
          inlineMessage: false,
          statusIcon: false,
          validateOnRuleChange: true,
          size: 'small'
        },
        submitBtn: false,
        resetBtn: false
      },
      rule: [{
        type: 'input',
        field: 'keyword',
        props: {
          clearable: true,
          placeholder: '门店名称'
        }
      }]
    };
  },
  methods: {
    handleChange: function handleChange() {
      var _this = this;
      this.model.submit(function (formData) {
        _this.$emit('change', formData);
      });
    }
  }
};
exports.default = _default;