"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formRule = exports.formOption = void 0;
var formOption = {
  form: {
    showMessage: true,
    inlineMessage: false,
    statusIcon: true,
    validateOnRuleChange: true,
    labelWidth: '100px',
    size: 'small'
  },
  row: {
    gutter: 10
  },
  submitBtn: false,
  resetBtn: false
};
exports.formOption = formOption;
var formRule = [{
  type: 'input',
  field: 'title',
  title: '名称',
  props: {
    placeholder: '请输入部门名称'
  },
  validate: [{
    required: true,
    message: '部门名称不能为空'
  }]
}, {
  type: 'input',
  field: 'remark',
  title: '备注',
  props: {
    placeholder: '请输入备注'
  }
}];
exports.formRule = formRule;