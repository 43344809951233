var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value.length > 0
    ? _c(
        "div",
        { staticClass: "category-container" },
        [
          _c(
            "el-table",
            {
              ref: "table",
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "current-row-key": 0,
                size: "medium",
                "show-header": false,
                "row-class-name": "pointer category",
                "highlight-current-row": "",
                fit: "",
              },
              on: { "row-click": _vm.handleSelect },
            },
            [
              _c("el-table-column", {
                attrs: { label: "名称", "min-width": "140px", align: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.editEnabled
                            ? _c(
                                "div",
                                { staticClass: "flex align-center tools" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "删除",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-close text-gray margin-right-xs",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.handleRemove(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "编辑",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-edit text-gray margin-right-xs",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.handleUpdate(
                                              scope.row.id
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.treeEnabled
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: "子分类",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-plus text-gray",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleCreate(
                                                  scope.row.id
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.editable,
                                expression: "scope.row.editable",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              placeholder: "请输入",
                              autofocus: scope.row.editable,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.handleSubmit(scope.$index, scope.row)
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSubmit(scope.$index, scope.row)
                              },
                            },
                            model: {
                              value: scope.row.title,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "title", $$v)
                              },
                              expression: "scope.row.title",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !scope.row.editable,
                                  expression: "!scope.row.editable",
                                },
                              ],
                              staticClass: "text-cut",
                              on: {
                                dblclick: function ($event) {
                                  return _vm.handleUpdate(scope.row.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.title))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1814695931
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }