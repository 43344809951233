"use strict";

var _interopRequireDefault = require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.operation = operation;
var _request = _interopRequireDefault(require("@/utils/request"));
function operation(params) {
  return (0, _request.default)({
    url: '/firm/operation/page',
    method: 'get',
    params: params
  });
}