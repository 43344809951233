"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _editForm = require("./editForm");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Edit',
  components: {},
  props: {
    group: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      temp: {},
      model: {},
      formRule: _editForm.formRule,
      formOption: _editForm.formOption,
      formDisable: false,
      btnLoading: false,
      modelChange: false,
      formVisible: false,
      nodeTop: [{
        'id': 0,
        'title': '顶级'
      }]
    };
  },
  computed: {},
  watch: {
    'model.form': {
      handler: function handler(val) {
        if (typeof val !== 'undefined') {
          this.modelChange = true;
        }
      },
      immediate: true,
      deep: true
    },
    formVisible: function formVisible() {
      this.handleReset();
    },
    group: function group(val) {
      this.model.form && this.model.mergeRule('group', {
        options: this.group
      });
    }
  },
  created: function created() {},
  methods: {
    formMounted: function formMounted() {
      this.model.mergeRule('group', {
        options: this.group
      });
    },
    handleClose: function handleClose(done) {
      var _this = this;
      if (this.btnLoading) {
        return;
      }
      if (this.modelChange) {
        this.$confirm('更改将不会被保存，确定要取消吗？').then(function (_) {
          _this.formVisible = false;
        }).catch(function (_) {});
      } else {
        this.formVisible = false;
      }
    },
    handleReset: function handleReset() {
      this.temp = {};
      this.model.form && this.model.resetFields();
      this.modelChange = false;
    },
    handleCreate: function handleCreate() {
      this.btnLoading = false;
      this.formVisible = true;
    },
    handleUpdate: function handleUpdate(id) {
      this.formVisible = true;
    },
    handleEdit: function handleEdit(temp) {
      var _this2 = this;
      this.formVisible = true;
      this.$nextTick(function () {
        _this2.temp = temp;
        _this2.model.form && _this2.model.setValue(temp);
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      this.model.submit(function (form) {
        Object.assign(_this3.temp, form);
        var temp = _this3.temp;
        var field = {
          props: {},
          col: {
            span: 14
          }
        };
        var props = {};
        for (var k in temp) {
          if (k.indexOf('props_') > -1) {
            var arr = k.split('_');
            field.props[arr[1]] = temp[k];
            delete form[k];
          } else {
            field[k] = temp[k];
          }
        }
        _this3.$emit('updateField', field);
        _this3.formVisible = false;
      });
    }
  }
};
exports.default = _default2;