var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "handle-container", attrs: { align: "bottom" } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "margin-top-sm",
              attrs: { type: "card", "before-leave": _vm.handleApp },
              model: {
                value: _vm.appIndex,
                callback: function ($$v) {
                  _vm.appIndex = $$v
                },
                expression: "appIndex",
              },
            },
            _vm._l(_vm.apps, function (item, key) {
              return _c(
                "el-tab-pane",
                { key: key, attrs: { label: item.title, name: key } },
                [
                  key === _vm.appIndex
                    ? _c("form-create", {
                        attrs: { rule: item.fields, option: _vm.formOption },
                        on: {
                          mounted: function ($event) {
                            return _vm.formMounted(_vm.appIndex, key, $event)
                          },
                        },
                      })
                    : _vm._e(),
                  key === _vm.appIndex
                    ? _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("group-page", {
                            attrs: {
                              "tree-enabled": false,
                              "edit-enabled": false,
                            },
                            on: { selected: _vm.handleGroup },
                            model: {
                              value: item.groups,
                              callback: function ($$v) {
                                _vm.$set(item, "groups", $$v)
                              },
                              expression: "item.groups",
                            },
                          }),
                          item.groups.length > 0
                            ? _c(
                                "div",
                                { staticClass: "flex-auto" },
                                [
                                  _c("form-create", {
                                    attrs: {
                                      rule: item.groups[_vm.groupIndex].fields,
                                      option: _vm.formOption,
                                    },
                                    on: {
                                      mounted: function ($event) {
                                        return _vm.formMounted(
                                          _vm.groupIndex,
                                          "group",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: {
                type: "error",
                icon: "el-icon-refresh",
                loading: _vm.formLoading,
              },
              on: { click: _vm.handleReset },
            },
            [_vm._v("重置配置")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: {
                type: "success",
                icon: "el-icon-upload",
                loading: _vm.formLoading,
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("提交保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }