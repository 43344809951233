var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-filter" },
    [
      _c("form-create", {
        attrs: { rule: _vm.rule, option: _vm.option },
        on: { "keyword-enter": _vm.handleChange, change: _vm.handleChange },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }